<!-- 课程类型 -->
<template>
  <div class="flex-col space-y-20 page">

    <div class="flex-col section_3">
      <div class="flex-row justify-between group_3">
        <div class="flex-row items-start text-wrapper"><span class="text_2">{{curriculum_name}} ></span><span class="text_2"> 缴费人数</span></div>
		<div class="flex-row">
		  <img
			src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994929819924446.png"
			class="image_7" @click="gobackBtn" />
		  <span class="text_34" @click="gobackBtn">返回</span>
		</div>
      </div>
      <div class="flex-row justify-center group_4">
        <span class="text_11">姓名</span>
        <el-input placeholder="请输入内容" v-model="keyword" clearable class="text_12" style="width: 15rem;"></el-input>
        <div class="flex-col items-center text-wrapper_2" @click="searchBtn()"><span class="text_13">查询</span></div>
        <div class="flex-col items-center text-wrapper_3" @click="resetBtn()"><span class="text_14">重置</span></div>
      </div>
      <div class="flex-col space-y-14 group_7" v-loading="loading" element-loading-text="数据正在加载中">
        <div v-if="list.length > 0">
          <el-table 
          ref="multipleTable"
          :data="list" 
          style="width: auto" 
          :header-cell-style="{background:'#eef0f7bf'}" 
          v-loading="loading" 
          element-loading-text = "数据正在加载中" 
          element-loading-spinner = "el-icon-loading">
                  <el-table-column :reserve-selection="true" type="selection" width="55" align="center"></el-table-column>
            <el-table-column type="index" label="序号" width="auto" align="center"></el-table-column>
                  <el-table-column prop="name" label="姓名" width="400px" align="center"></el-table-column>
                  <el-table-column prop="sex_txt" label="性别" width="auto" align="center"></el-table-column>
                  <el-table-column prop="id_card" label="身份证" width="auto" align="center"></el-table-column>
                  <el-table-column prop="mobile" label="联系方式" width="auto" align="center"></el-table-column>
                  <el-table-column prop="create_time" label="报名时间" width="auto" align="center"></el-table-column>
                  <el-table-column prop="pay_status_txt" label="缴费状态" width="auto" align="center"></el-table-column>
                  <el-table-column prop="score" label="考试成绩" width="auto" align="center"></el-table-column>
                </el-table>

        </div>
        <div v-else>
          <div class="flex-col space-y-22 group_7">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
      <div class="page-box" v-if="list.length > 0">
        <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="count"
          :page-size="limit">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        loading: false,
        page: 1,
        limit: 10,
        totalpage: 0,
        count: 0,
		curriculum_name:'',
        curriculumCateArr: [],
        curriculum_cate_id: '',
        curriculum_id: '',
        trainTypeArr: [],
        train_type_id: '',
        status: '',
        keyword: '',
        list: [],
        tostud: '',//进入学习
        tostudId:'',//课程订单id
      };
    },
    created() {
      let that = this;
	  that.curriculum_name = that.$route.query.curriculum_name;
      //获取课程列表
      that.getBaomingUser();
    },
    methods: {
      
      getBaomingUser() {
        let that = this;
        //that.$store.commit('showLoading')
        that.loading = true;
        let param = {
			curriculum_id: that.$route.query.curriculum_id,
			status:2,//已缴费人数
			page: that.page,
			limit: that.limit,
			keyword: that.keyword,
        };
        that.$request.getBaomingUser(param).then((res) => {
          if (res.code == 200) {
            that.list = res.datas;
            if (that.page + 1 < res.total_page) {
              that.page = that.page + 1;
            }

            that.totalpage = res.total_page;
            that.count = res.count;
            that.loading = false;
          }
        });
      },
      //点击页码
      handleCurrentChange(val) {
        let that = this;
        that.page = val;
        that.getBaomingUser();
      },
      //搜索
      searchBtn() {
        let that = this;
        that.page = 1;
        that.totalpage = 0;
        that.getBaomingUser();
      },
      //重置搜索
      resetBtn() {
        let that = this;
        that.page = 1;
        that.totalpage = 0;
        that.keyword = '';
        that.getBaomingUser();
      },
	  addBaomingClick(data){
		  let that=this;
		  //报名成功，跳转到支付页面
		  that.$router.push({
		    'path': '/company/applicant',
		    query: {
		      curriculum_id: data.id,
		    }
		  })
	  },
      // 去学习
      goTrainTostudy(e){
        let that = this;
        console.log(e);
        that.tostudId = e.target.dataset.id;
        that.curriculum_id = e.target.dataset.curriculum_id;
        that.getTrainTostudy()
      },
	  //返回
	  gobackBtn() {
	    let that = this;
	    that.$router.go(-1)
	  },

      //进入课程学习
      getTrainTostudy() {
        let that = this;
        that.loading = true;
        let param = {
          order_id: that.tostudId,
        };
        that.$request.getTrainTostudy(param).then((res) => {
          if (res.code == 200) {
            let datas = res.datas;
            that.tostud = datas;
            if(datas.target == 1){
              //跳转博奥第三方链接
              window.open(datas.data,"_blank");
            }else{
              //本地链接内部跳转
              that.$router.push({
                path: '/user/training/video',

                query: {
                  curriculum_id: that.curriculum_id
                }
              });
            }
          }
        });
      },
    },
  };
</script>

<style scoped lang="css">
  .space-y-20>*:not(:first-child) {
    margin-top: 1.25rem;
  }

  .page {
    background-color: #f5f6fa;
    width: calc(100% - 220px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 1.25rem 1.25rem 1.88rem;
  }

  .page-box {
    margin: 1.65rem 0;
  }

  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.13rem;
    background-color: #ffffff;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }

  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }
  .image_7 {
    flex-shrink: 0;
    width: 1.56rem;
    height: 1.56rem;
    cursor: pointer;
  }
  .text_34 {
    margin: 0.41rem 0 0.38rem 0.28rem;
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.78rem;
    cursor: pointer;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .space-x-15>*:not(:first-child) {
    margin-left: 0.94rem;
  }

  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }

  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }

  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.88rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }

  .image_2 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }

  .section_3 {
    /* margin-right: 1.25rem; */
    padding: 0.94rem 0.94rem;
    align-self: auto;
    background-color: #ffffff;
    border-radius: 0.63rem;
    /* width: 71.56rem; */
  }

  .group_3 {
    margin-right: 0.31rem;
    overflow: hidden;
    border-bottom: solid 0.016rem #c0c4cc;
  }

  .text-wrapper {
    padding: 0.22rem 0 1.28rem;
    width: 6.75rem;
    border-bottom: solid 0.16rem #0074ff;
  }

  .text_2 {
    color: #0074ff;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .group_4 {
    margin-top: 1.69rem;
    align-self: flex-start;
    overflow: hidden;
    /* width: 59.69rem; */
  }

  .space-x-8>*:not(:first-child) {
    margin-left: 0.5rem;
  }

  .group_5 {
    overflow: hidden;
    width: 12.63rem;
    /* height: 2.19rem; */
  }

  .text_3 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-45>*:not(:first-child) {
    margin-left: 2.81rem;
  }

  .section_4 {
    flex-shrink: 0;
    padding: 0.59rem 0.5rem 0.66rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }

  .text_4 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .image_6 {
    margin-right: 0.16rem;
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .group_6 {
    margin-left: 0.94rem;
    overflow: hidden;
    width: 10.69rem;
    height: 2.19rem;
  }

  .text_5 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-14>*:not(:first-child) {
    margin-left: 0.88rem;
  }

  .text_7 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_11 {
    margin-left: 1rem;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_1 {
    margin-left: 0.53rem;
    padding: 0.59rem 0 0.72rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }

  .text_12 {
    margin-left: 0.5rem;
    margin-right: 0.56rem;
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_2 {
    margin-left: 0.94rem;
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_13 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
    cursor: pointer;
  }

  .text-wrapper_3 {
    margin-left: 0.91rem;
    padding: 0.63rem 0 0.72rem;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
    cursor: pointer;
  }

  .text_14 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-y-14>*:not(:first-child) {
    margin-top: 0.88rem;
  }

  .group_7 {
    padding: 1.25rem 0.44rem 0;
  }

  .section_5 {
    margin-bottom: 1rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .section_6 {
    padding: 0.53rem 1.13rem 0.59rem;
    background-color: #fbfcff;
    border: solid 0.063rem #dcdfe6;
  }

  .text_15 {
    align-self: center;
    color: #9a9da7;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_4 {
    margin-right: 0.41rem;
    padding: 0.47rem 0 0.66rem;
    background-color: #fef0f0;
    border-radius: 0.31rem;
    width: 3.75rem;
    height: 1.88rem;
    border: solid 0.031rem #fde2e2;
  }

  .text_17 {
    color: #f56c6c;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .space-y-10>*:not(:first-child) {
    margin-top: 0.63rem;
  }

  .group_8 {
    margin: 0.94rem 1.53rem 0 1.16rem;
    overflow: hidden;
  }

  .text_19 {
    margin-bottom: 0.13rem;
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .space-x-6>*:not(:first-child) {
    margin-left: 0.38rem;
  }

  .group_10 {
    padding: 0.16rem 0 0.16rem 0.094rem;
    overflow: hidden;
    /* width: 8rem; */
    height: 1.25rem;
  }

  .text_21 {
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .text_23 {
    margin-top: 0.19rem;
    flex-shrink: 0;
    color: #ff5f5a;
    font-size: 1rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.66rem;
  }

  .space-x-10>*:not(:first-child) {
    margin-left: 0.63rem;
  }

  .group_11 {
    align-self: flex-start;
  }

  .text-wrapper_5 {
    flex: 1 1 auto;
    padding: 0.47rem 0 0.66rem;
    background-color: #ffffff;
    border-radius: 0.31rem;
    width: 4.25rem;
    height: 1.88rem;
    border: solid 0.031rem #b3d8ff;
  }

  .text_25 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .text-wrapper_6 {
    flex-shrink: 0;
    padding: 0.47rem 0 0.66rem;
    background-color: #ffffff;
    border-radius: 0.31rem;
    width: 2.75rem;
    height: 1.88rem;
    border: solid 0.031rem #b3d8ff;
  }

  .text_27 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .section_7 {
    margin-top: 0.88rem;
    padding: 0.41rem 1.13rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .text_29 {
    color: #7d7d8c;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_30 {
    align-self: center;
  }

  .group_12 {
    margin-right: 0.47rem;
  }

  .text-wrapper_7 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    color: #fff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text-wrapper_71 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_31 {
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_311 {
    color: #fff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_8 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_33 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_9 {
    margin-right: 0.41rem;
    padding: 0.47rem 0 0.66rem;
    background-color: #f4f4f5;
    border-radius: 0.31rem;
    width: 3.75rem;
    height: 1.88rem;
    border: solid 0.031rem #e9e9eb;
  }

  .text_36 {
    color: #909399;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .group_14 {
    align-self: flex-start;
  }

  .text-wrapper_10 {
    margin-top: 0.88rem;
    padding: 1.03rem 0 1.16rem;
    background-color: #ffffff;
    /* width: 68.75rem; */
    border: solid 0.063rem #dcdfe6;
  }

  .text_43 {
    margin-left: 1.13rem;
  }

  .group_16 {
    align-self: flex-start;
  }

  .group_18 {
    padding: 0.16rem 0;
    overflow: hidden;
    width: 8rem;
    height: 1.25rem;
  }

  .group_19 {
    align-self: flex-start;
  }

  .section_8 {
    margin-top: 0.88rem;
    padding: 0.41rem 1.13rem 0.41rem 1.13rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .text_60 {
    align-self: center;
  }

  .text-wrapper_11 {
    padding: 0.69rem 0 0.47rem;
    background-color: #f5f8ff;
    border-radius: 0.31rem 0.31rem 0 0;
    width: 5.63rem;
    height: 1.97rem;
  }

  .text-wrapper_12 {
    padding: 0.69rem 0 0.47rem;
    background-color: #0074ff;
    border-radius: 0.31rem 0.31rem 0 0;
    width: 5.63rem;
    height: 1.97rem;
  }
</style>
